
























import {
  defineComponent,
  ref,
  onMounted,
} from '@nuxtjs/composition-api';

import { Stripe } from '~/modules/checkout/stripe';

import {
  usePaymentProvider,
  useStripePublicKey,
} from '~/composables';

import type { AvailablePaymentMethod } from '~/modules/GraphQL/types';
import { SkeletonLoader } from '~/components';

export default defineComponent({
  name: 'StripePaymentProvider',
  components: {
    SkeletonLoader,
    Stripe
  },
  emits: ['stripe-status'],
  setup(_props, { emit }) {
    const {
      load,
      save
    } = usePaymentProvider();

    const { load: loadStripePublicKey } = useStripePublicKey();

    const paymentMethods = ref<AvailablePaymentMethod[]>([]);
    const selectedPaymentMethodCode = ref<string | null>(null);
    const stripeRef = ref(null);
    const apiKey = ref('');
    const isStripeReady = ref(false);

    const definePaymentMethods = async (paymentMethodCode: string) => {

      paymentMethods.value = await save({
        paymentMethod: {
          code: paymentMethodCode,
        },
      });

      selectedPaymentMethodCode.value = paymentMethodCode;
    };

    const triggerStripe = () => {
      if (selectedPaymentMethodCode.value == 'stripe_payments') {
        return stripeRef.value.useStripe();
      } else {
        return;
      }
    };

    const handlePaymentStatus = (status) => {
      emit('stripe-status', status);
    };

    const handleIsStripeReady = () => {
      isStripeReady.value = true;
    };

    onMounted(async () => {
      // @ts-ignore
      apiKey.value = await loadStripePublicKey();
      paymentMethods.value = await load();
      await definePaymentMethods(paymentMethods.value[1].code);
    });

    return {
      apiKey,
      triggerStripe,
      stripeRef,
      handlePaymentStatus,
      paymentMethods,
      selectedPaymentMethodCode,
      definePaymentMethods,
      handleIsStripeReady,
      isStripeReady
    };
  },
});
